<template>
  <div
    ref="chipsFiltersWrapperRef"
    class="chips-filters-wrapper"
  >
    <div class="chips-filters">
      <HADSChips
        v-for="(chips, index) of chipsList"
        :id="`${index}, ${chips.key}`"
        :key="index"
        v-model="modelValue"
        :value="{ [chips.key]: chips.value }"
      >
        {{ chips.text }}
      </HADSChips>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref } from 'vue'
import HADSChips from '@/domains/design_system/atoms/HADSChips.vue'
import { CategoryKeys, ActivityTypesKeys } from '@/helpers/enums'
import { FILTER_DATE_OPTIONS_KEY } from '~/domains/search/interfaces/filters.interface'

const modelValue = defineModel<any>()

interface Chips {
  text: string
  value: any
  modelValue: any
  key: string
  isSelected: boolean
}

const chipsListDefault: Chips[] = [
  {
    text: 'Ce week-end',
    value: "NEXT_WEEK_END",
    key: 'date',
    isSelected: false,
    modelValue: undefined
  },
  {
    text: 'Gratuit',
    value: 0,
    key: 'min_price',
    isSelected: false,
    modelValue: undefined
  },
  {
    text: 'Spectacles',
    value: ActivityTypesKeys.spectacle,
    key: 'activity_type',
    isSelected: false,
    modelValue: undefined
  },
  {
    text: 'Conférences',
    value: ActivityTypesKeys.conference,
    key: 'activity_type',
    isSelected: false,
    modelValue: undefined
  },
  {
    text: 'Stages & ateliers',
    value: ActivityTypesKeys.atelier,
    key: 'activity_type',
    isSelected: false,
    modelValue: undefined
  },
  {
    text: 'Musique',
    value: CategoryKeys.musique,
    key: 'category_tags',
    isSelected: false,
    modelValue: undefined
  },
  {
    text: 'Sport',
    value: CategoryKeys.sport,
    key: 'category_tags',
    isSelected: false,
    modelValue: undefined
  },
  {
    text: 'Solidarité',
    value: CategoryKeys['solidarite--et--action--sociale'],
    key: 'category_tags',
    isSelected: false,
    modelValue: undefined
  }
]

const chipsList: Ref<Chips[]> = ref(chipsListDefault)
</script>

<style scoped lang="scss">
.chips-filters-wrapper {
  cursor: grab;
  overflow-x: auto;
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  margin: 0 -2em;
  padding: 0.2em 2em;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

.chips-filters {
  display: flex;
  gap: var(--hads-spacing-xsmall);
}

.chips {
  white-space: nowrap;
}
</style>
