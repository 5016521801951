<template>
  <section class="Experiences Section">
    <div class="Experiences--List">
      <div class="Experiences--Intro">
        <h2 class="Section--Title h3">
          <span class="Highlighted Highlighted-RetrowaveTransparent">
            Trouvez
          </span>
          votre prochaine activité
        </h2>
        <p class="Section--Subtitle">
          Découvrez des idées d’activités uniques proposées par des
          associations locales.
        </p>
        <SeoLink
          to="/e/recherche?tab=activites"
          data-ux="Showcase_HomeGP_Experiences_SeeAll"
          text="Voir toutes les activités"
        />
      </div>
      <div class="Experiences--ListItem Item-01">
        <nuxt-link
          to="/e/act/atelier"
          data-ux="Showcase_HomeGP_Experiences_Workshops"
        >
          <figure>
            <img
              src="~/assets/homepage/background-activity-workshop.2a1040e.jpg"
              alt="Ateliers"
              loading="lazy"
            >
            <figcaption>
              <span class="Highlighted Highlighted-Desert">
                Ateliers
              </span>
            </figcaption>
          </figure>
          <h4 class="Experiences--ListItemName">Ateliers</h4>
        </nuxt-link>
      </div>
      <div class="Experiences--ListItem Item-02">
        <nuxt-link
          to="/e/act/conference"
          data-ux="Showcase_HomeGP_Experiences_Conferences"
        >
          <figure>
            <img
              src="@/assets/homepage/background-sectors-education-formation.fc1813c.jpg"
              alt="Conférences"
              loading="lazy"
            >
            <figcaption>
              <span class="Highlighted Highlighted-Iris">
                Conférences
              </span>
            </figcaption>
          </figure>
          <h4 class="Experiences--ListItemName">Conférences</h4>
        </nuxt-link>
      </div>
      <div class="Experiences--ListItem Item-03">
        <nuxt-link
          to="/e/act/concert"
          data-ux="Showcase_HomeGP_Experiences_Concerts"
        >
          <figure>
            <img
              src="@/assets/homepage/background-activity-festival.d6791a4.jpg"
              alt="Concerts"
              loading="lazy"
            >
            <figcaption>
              <span class="Highlighted">Concerts</span>
            </figcaption>
          </figure>
          <h4 class="Experiences--ListItemName">Concerts</h4>
        </nuxt-link>
      </div>
      <div class="Experiences--ListItem Item-04">
        <nuxt-link
          to="/e/act/cours"
          data-ux="Showcase_HomeGP_Experiences_Lessons"
        >
          <figure>
            <img
              src="~/assets/homepage/background-activity-lesson-vert.33d49d8.jpg"
              alt="Cours"
              loading="lazy"
              class="visible-lg"
            >
            <figcaption>
              <span class="Highlighted Highlighted-Retrowave">
                Cours
              </span>
            </figcaption>
          </figure>
          <h4 class="Experiences--ListItemName">Cours</h4>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
import SeoLink from '@/components/SeoLink/SeoLink.vue'

export default {
  name: 'HomeExperiences',
  components: {
    SeoLink
  }
}
</script>

<style scoped lang="scss">
.Experiences {
  &--List {
    display: flex;
    gap: 2em;
    flex-direction: column;

    @include mediaQuery(600) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        'intro intro'
        'item1 item2'
        'item3 item4';
    }

    @include mediaQuery(1200) {
      grid-template: repeat(2, 1fr) / repeat(3, 1fr);
      grid-template-areas:
        'intro item1 item4'
        'item2 item3 item4';
    }

    &Item {
      &.Item-01 {
        grid-area: item1;
      }

      &.Item-02 {
        grid-area: item2;
      }

      &.Item-03 {
        grid-area: item3;
      }

      &.Item-04 {
        grid-area: item4;
      }

      &Name {
        margin-bottom: 0;
        line-height: 1;
      }

      a {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        height: 100%;
        color: var(--ha-color-background);
        font-weight: bold;
        font-size: 2em;

        figure {
          position: relative;
          display: grid;
          place-items: center;
          flex-grow: 1;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            aspect-ratio: 2/1;
          }

          figcaption {
            position: absolute;
          }
        }
      }
    }
  }

  &--Intro {
    grid-area: intro;
    display: flex;
    flex-direction: column;

    .Section {
      &--Title {
        font-size: 3em;
      }

      &--Subtitle {
        font-size: 1.5em;
      }
    }
  }
}
</style>
