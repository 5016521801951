<template>
  <div :class="['Oblong', props.color]"></div>
</template>

<script setup lang="ts">
import { AvailableOblongColors } from '@/domains/design_system/models/colors'

const props = withDefaults(defineProps<{ color: AvailableOblongColors }>(), {
  color: AvailableOblongColors.Mint
})
</script>

<style scoped lang="scss">
div {
  position: absolute;
  z-index: -1;
  border-radius: var(--hads-radius-roundest);

  &.Iris {
    background-color: var(--ha-color-primary);
  }

  &.IrisLight {
    background-color: set-alpha('primary', 5%);
  }

  &.Mint {
    background-color: var(--ha-color-secondary);
  }

  &.MintLight {
    background-color: set-alpha('secondary', 5%);
  }

  &.Desert {
    background-color: var(--ha-color-accent-desert);
  }

  &.DesertLight {
    background-color: set-alpha('accent-desert', 5%);
  }

  &.Retrowave {
    background-color: var(--ha-color-accent-retrowave);
  }

  &.RetrowaveLight {
    background-color: set-alpha('accent-retrowave', 5%);
  }

  &.Air {
    background-color: var(--ha-color-background);
  }

  &.Green {
    background: var(--hads-color-mint-opacity-50);
  }

  &.Rose {
    background: var(--hads-color-retrowave-opacity-50);
  }
}
</style>
