<template>
  <Pastille
    :class="[
      'Hero-Injectable-Background',
      {
        ManageZoneHovered,
        SearchZoneHovered
      }
    ]"
    :pastille-set="pastilleSet"
  >
    <section class="SplitHero">
      <div
        class="ManageZone SplitHero--Side SplitHero--Side-Left"
        @mouseover="ManageZoneHovered = true"
        @mouseleave="ManageZoneHovered = false"
      >
        <div class="TextZone">
          <span class="Heading Heading-PageTitle TextZone--UpTitle">
            Le bon outil pour votre association
          </span>
          <p class="TextZone--Title">
            Je
            <span class="Highlighted Highlighted-DesertTransparent"
              >gère</span
            >
            une association
          </p>
          <p class="TextZone--Desc">
            qui cherche des outils gratuits pour se développer et se
            financer&nbsp;?
            <span class="bold">{{
              showcaseOrganizationCounter
            }}</span>
            associations comme la vôtre ont choisi HelloAsso.
          </p>
          <HADSButton
            v-segment="{
              name: 'Home Hero CTA',
              payload: {
                cta_link_to: 'Home Showcase',
                item_label: 'Découvrir nos outils',
                href: '/associations'
              }
            }"
            href="/associations"
            data-ux="Showcase_HomeGP_Hero_DiscoverTools"
          >
            Découvrir nos outils
          </HADSButton>
        </div>
      </div>
      <div
        class="SearchZone SplitHero--Side SplitHero--Side-Right"
        @mouseover="SearchZoneHovered = true"
        @mouseleave="SearchZoneHovered = false"
      >
        <div class="TextZone">
          <span class="Heading Heading-PageTitle TextZone--UpTitle">
            Le bon endroit pour votre passion
          </span>
          <p class="TextZone--Title">
            Je
            <span class="Highlighted Highlighted-RetrowaveTransparent"
              >cherche</span
            >
            une association
          </p>
          <p class="TextZone--Desc">
            qui propose des activités. Chaque mois, des millions de
            personnes trouvent des projets associatifs parmi les
            <span class="bold">200 000</span> proposés sur HelloAsso.
          </p>
          <HADSButton
            v-segment="{
              name: 'Home Hero CTA',
              payload: {
                cta_link_to: 'Recherche Explore',
                item_label: 'Rechercher une activité',
                href: '/e/recherche'
              }
            }"
            href="/e/recherche"
            color="basic"
            data-ux="Showcase_HomeGP_Hero_SearchActivity"
            rel="nofollow"
          >
            Rechercher une activité
          </HADSButton>
        </div>
      </div>
      <div class="VisualZone">
        <img
          src="@/assets/homepage/hammer.1aa90f4.svg"
          alt="Le bon outil pour votre association"
          class="Visual Visual--SVG Visual--SVG-Hammer"
        />
        <img
          src="@/assets/homepage/magnifying-glass.a7c55fb.svg"
          alt="Le bon endroit pour votre passion"
          class="Visual Visual--SVG Visual--SVG-Glass"
        />
      </div>
    </section>
  </Pastille>
</template>

<script setup lang="ts">
import { ref } from '#imports'
import Pastille from './Pastille.vue'
import HADSButton from '@/domains/design_system/atoms/HADSButton/HADSButton.vue'

// TODO use asyncData -> here it's failed bc its null at first load
// const { data : showcaseOrganizationCounter2} = await useAsyncData('showcaseOrganizationCounter', () => getOrganizationCount())
// const showcaseOrganizationCounter = await getOrganizationCount()
const showcaseOrganizationCounter = '322 350'
const ManageZoneHovered = ref(false)
const SearchZoneHovered = ref(false)
const pastilleSet = ref({
  Mint: {
    x: 72,
    y: 87,
    width: 2,
    height: 2
  },
  Desert: {
    x: 90,
    y: 92,
    width: 11,
    height: 5
  },
  Retrowave: {
    x: 53,
    y: 8,
    width: 3,
    height: 3
  },
  Green: {
    x: 60,
    y: -4,
    width: 3,
    height: 5
  },
  Rose: {
    x: 32,
    y: -5,
    width: 10,
    height: 6
  }
})
</script>

<style lang="scss" scoped>
.SplitHero {
  .TextZone {
    .bold {
      color: var(--hads-color-iris);
      font-weight: bold;
    }

    &--UpTitle {
      text-transform: uppercase;
      color: var(--hads-color-iris);
      font-size: 1.2em;
      display: block;
      margin-bottom: 0.8em;
    }

    &--Title {
      color: #2e2f5e;
      font-size: clamp(20px, 6vw, 5.5em);
      font-weight: 700;
      line-height: 1;
      position: relative;
      margin-bottom: 0.2em;

      .Highlighted {
        --underline-width: 24;
        --underline-offset-y: -0.15em;

        color: var(--hads-color-iris);
      }
    }

    &--Desc {
      color: var(--ha-color-text);
    }
  }

  .VisualZone {
    position: absolute;
    pointer-events: none;
  }

  &--Side-Left .TextZone--Desc {
    max-width: 31em;
  }

  &--Side-Right {
    .TextZone--Desc {
      max-width: 35em;
    }

    .hads-button {
      border-radius: 2em;

      --hads-button-background: var(--hads-color-midnight);
    }
  }

  @include mediaQuery(1000) {
    &--Side-Right .TextZone {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
    }
  }
}

@media (width < 500px) {
  .SplitHero .VisualZone {
    display: none;
  }
}

@media (width < 750px) {
  .SplitHero .VisualZone .Visual--SVG {
    height: 30%;
  }
}

@media (width < 1000px) {
  :deep(.Pastille-InnerWrapper .Oblong-Container) {
    display: none;
  }

  .SplitHero {
    display: grid;
    grid-template-rows: repeat(2, 1fr);

    &--Side {
      @media (width < 500px) {
        padding: 2em;
      }

      @media (width >=500px) {
        padding: 4em;
      }

      &-Left {
        background: #f5f4fb;
      }

      &-Right {
        box-shadow: inset 0 -5em 5em -5em rgb(46 47 94 / 15%);
        position: relative;
        z-index: 2;
      }
    }

    .VisualZone {
      @media (width >=500px) {
        top: 0;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: repeat(2, 50%);
        justify-items: end;
      }

      @media (width >=750px) {
        .Visual--SVG {
          height: 40%;
        }
      }
    }
  }
}

@media (width >=1000px) {
  .Hero-Injectable-Background {
    position: relative;

    &.ManageZoneHovered::before {
      width: 60%;
    }

    &.SearchZoneHovered::before {
      width: 41%;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      position: absolute;
    }

    &::before {
      transition: 0.2s;
      background: #f5f4fb;
      z-index: -2;
    }

    &::after {
      top: 0;
      background-image: url('@/assets/images/slugs.png');
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: -1;
      background-position-x: min(80%, 30em);
      background-position-y: bottom;
      background-size: min(35%, 15em);
    }
  }

  .SplitHero {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 -5em 5em -5em rgb(46 47 94 / 15%);

    .ManageZone,
    .SearchZone,
    .VisualZone {
      height: 100%;
      display: grid;
      place-items: center;
      padding: 4vw;
    }

    .ManageZone,
    .SearchZone {
      width: 50%;
      position: relative;
      transition: 0.2s;
    }

    .ManageZone {
      padding-right: 7em;
    }

    .SearchZone {
      padding-left: 7em;

      &:hover ~ .VisualZone > .Visual--SVG-Glass {
        transform: scale(1);
        opacity: 1;
      }

      &:hover ~ .VisualZone > .Visual--SVG-Hammer {
        transform: scale(0);
        opacity: 0;
      }
    }

    .VisualZone {
      width: 28%;
      pointer-events: none;
      z-index: 3;

      img {
        position: absolute;

        &.Visual--SVG {
          transition: 0.2s;
        }

        &.Visual--SVG-Glass {
          transform: scale(0);
          opacity: 0;
        }
      }
    }
  }
}
</style>
