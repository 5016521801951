<template>
  <section class="height-spacer width-spacer">
    <h2 class="h3">
      {{ $t('seoMeshing.title') }}
    </h2>
    <p>{{ $t('seoMeshing.subtitle') }}</p>
    <TabsBar
      :tabs="Object.keys(Meshing)"
      :selected-tab="selectedTab"
      @select-tab="changeSelectedTab"
    />
    <TabsContent>
      <li
        v-for="[MeshName, Mesh] of Object.entries(Meshing)"
        :key="MeshName"
        :class="{ active: MeshName === selectedTab }"
        role="tabpanel"
      >
        <ul>
          <SeoLink
            v-for="meshEntry of Mesh"
            :key="meshEntry.dataUx"
            :href="meshEntry.href"
            :data-ux="meshEntry.dataUx"
            :text="meshEntry.text"
          />
        </ul>
      </li>
    </TabsContent>
  </section>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import SeoLink from '@/domains/design_system/molecules/SeoLink/SeoLink.vue'
import TabsBar from '@/domains/design_system/molecules/Tabs/TabsBar.vue'
import TabsContent from '@/domains/design_system/molecules/Tabs/TabsContent.vue'
import {
  Meshing,
  type MeshTypes
} from '@/services/seoMeshing.service'

const [firstKeyInMeshing] = Object.keys(Meshing)
const selectedTab = ref(firstKeyInMeshing)

const changeSelectedTab = (tab: MeshTypes) => {
  selectedTab.value = tab
}
</script>

<style scoped lang="scss">
p {
  font-size: 1.25em;
  padding: var(--hads-spacing-medium) 0;
}

.tabContent ul {
  display: grid;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  gap: 0.8em;

  @include mediaQuery(600, 'between', 800) {
    --columns: 2;
  }

  @include mediaQuery(800, 'between', 1000) {
    --columns: 3;
  }

  @include mediaQuery(1000) {
    --columns: 4;
  }
}
</style>
