<script setup lang="ts">
import {
  faBasketball,
  faPalette,
  faHandsHelping,
  faLeaf,
  faMusic,
  faPlus
} from '@fortawesome/pro-solid-svg-icons'
import { HaIcon } from '@ha/components-v3'
import { useI18n } from '#imports'

const i18n = useI18n()
</script>

<template>
  <section class="Categories Section">
    <div class="container-xl">
      <h2 class="Section--Title h3">Laissez-vous guider...</h2>
      <h3 class="Section--Subtitle">
        Parcourez toutes les activités par thématique.
      </h3>
      <div class="Categories--List">
        <nuxt-link
          to="/e/cat/sport"
          data-ux="Showcase_HomeGP_Categories_Sport"
          class="Categories--ListItem"
        >
          <HaIcon
            :icon="faBasketball"
            class="Categories--ListItemIcon"
            aria-hidden="true"
          />
          <span class="Categories--ListItemResult">
            <span class="Categories--ListItemResultNumber">
              57&nbsp;643
            </span>
            <br />
            résultats
          </span>
          <span class="Categories--ListItemLabel">
            <span class="visually-hidden">
              {{ $t('homeCategories.forCategory') }}
            </span>
            Sport
          </span>
        </nuxt-link>
        <nuxt-link
          to="/e/cat/arts--et--culture"
          data-ux="Showcase_HomeGP_Categories_ArtsAndCulture"
          class="Categories--ListItem"
        >
          <HaIcon
            :icon="faPalette"
            class="Categories--ListItemIcon"
            aria-hidden="true"
          />
          <span class="Categories--ListItemResult"
            ><span class="Categories--ListItemResultNumber">
              58&nbsp;165
            </span>
            <br />
            résultats
          </span>
          <span class="Categories--ListItemLabel">
            <span class="visually-hidden">
              {{ $t('homeCategories.forCategory') }}
            </span>
            Arts et <br />Culture
          </span>
        </nuxt-link>
        <nuxt-link
          to="/e/cat/solidarite--et--action--sociale"
          data-ux="Showcase_HomeGP_Categories_SolidarityAndSocialAction"
          class="Categories--ListItem"
        >
          <HaIcon
            :icon="faHandsHelping"
            class="Categories--ListItemIcon"
            aria-hidden="true"
          />
          <span class="Categories--ListItemResult"
            ><span class="Categories--ListItemResultNumber">
              93&nbsp;057
            </span>
            <br />
            résultats
          </span>
          <span class="Categories--ListItemLabel">
            <span class="visually-hidden">
              {{ $t('homeCategories.forCategory') }}
            </span>
            Solidarité et <br />Action sociale
          </span>
        </nuxt-link>
        <nuxt-link
          to="/e/cat/ecologie--et--environnement"
          data-ux="Showcase_HomeGP_Categories_EcologyAndEnvironment"
          class="Categories--ListItem"
        >
          <HaIcon
            :icon="faLeaf"
            class="Categories--ListItemIcon"
            aria-hidden="true"
          />
          <span class="Categories--ListItemResult"
            ><span class="Categories--ListItemResultNumber">
              21&nbsp;280
            </span>
            <br />
            résultats
          </span>
          <span class="Categories--ListItemLabel">
            <span class="visually-hidden">
              {{ $t('homeCategories.forCategory') }}
            </span>
            Écologie et <br />Environnement
          </span>
        </nuxt-link>
        <nuxt-link
          to="/e/cat/musique"
          data-ux="Showcase_HomeGP_Categories_Music"
          class="Categories--ListItem"
        >
          <HaIcon
            :icon="faMusic"
            class="Categories--ListItemIcon"
            aria-hidden="true"
          />
          <span class="Categories--ListItemResult"
            ><span class="Categories--ListItemResultNumber">
              11&nbsp;208
            </span>
            <br />
            résultats
          </span>
          <span class="Categories--ListItemLabel">
            <span class="visually-hidden">
              {{ $t('homeCategories.forCategory') }}
            </span>
            Musique
          </span>
        </nuxt-link>
        <nuxt-link
          to="/e/recherche"
          data-ux="Showcase_HomeGP_Categories_SeeMore"
          rel="nofollow"
          class="Categories--ListItem"
        >
          <HaIcon
            :icon="faPlus"
            class="Categories--ListItemIcon"
            aria-hidden="true"
          />
          <span class="Categories--ListItemLabel"
            >Plus de thématiques</span
          >
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.Categories {
  .Section {
    &--Title {
      font-size: 1.9em;
      margin-bottom: 0;
    }

    &--Subtitle {
      font-size: 1.4em;
      font-weight: 600;
      color: #808080;
      margin-bottom: 1.2em;
    }
  }

  &--List {
    gap: 2em;
    display: grid;

    @media (500px <= width < 800px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (800px <= width < 1330px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (width >= 1330px) {
      grid-template-columns: repeat(6, 1fr);
    }

    &Item {
      border: 1px solid lightgrey;
      padding: 1em;
      border-radius: 8px;
      display: grid;
      grid-template-areas:
        'icon result'
        'label label';
      gap: 1em;
      width: 100%;
      transition: 0.2s;

      &:hover {
        border-color: var(--hads-color-iris);
        box-shadow: 0 0 1em rgb(46 47 94 / 10%);
        transform: translateY(-0.5em);
      }

      &:last-of-type &Icon {
        color: var(--hads-color-iris);
      }

      &Icon {
        font-size: 1.5em;
        color: var(--hads-color-mint);
      }

      &Result {
        grid-area: result;
        text-transform: uppercase;
        font-size: 0.6em;
        color: #808080;

        &Number {
          font-size: 1.3em;
          font-weight: 600;
        }
      }

      &Label {
        grid-area: label;
        font-weight: bold;
        align-self: end;
      }

      &Result,
      &Label {
        text-align: right;
      }
    }
  }
}
</style>
