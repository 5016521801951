import type { Article } from '@/models/Article.interface'

export function fetchBlogArticles(): Article[] {
  return [
    {
      title: 'Et si on arrivait à mesurer le pouvoir de la solidarité ?',
      textPreview: 'C\'est grâce à vous que les chiffres de l\'impact de HelloAsso montent toujours plus haut. Découvrez les résultats de notre premier rapport d’impact !',
      articleUrl: 'https://www.helloasso.com/impact',
      meta: {
        thumbnailUrl: 'https://cdn.helloasso.com/images/explore/edito/edito-impact.jpg',
        tag: 'Impact',
        publicationDate: '15 décembre 2023',
        readDuration: '7 min'
      }
    },
    {
      title: 'Les heureux témoignent derrière les euros',
      textPreview: '1 milliard d\'euros collectés par la communauté HelloAsso pour les associations.',
      articleUrl: 'https://www.helloasso.com/avis',
      meta: {
        thumbnailUrl: 'https://cdn.helloasso.com/images/explore/edito/edito-1m.jpg',
        tag: 'Témoignages',
        publicationDate: '28 juillet 2023',
        readDuration: '3 min'
      }
    },
    {
      title: 'Besoin d\'un accompagnement ?',
      textPreview: 'Participez à un atelier de découverte en ligne de nos outils pour faciliter la gestion de votre association.',
      articleUrl: 'https://app.livestorm.co/helloasso',
      meta: {
        thumbnailUrl: 'https://cdn.helloasso.com/images/explore/edito/edito-formations.jpg',
        tag: 'Évènements',
        publicationDate: '11 janvier 2024',
        readDuration: '2 min'
      }
    }
  ]
}
