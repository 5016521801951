import type { SeoLinkInterface } from '@/components/SeoLink/SeoLink.interface'

export type MeshTypes =
  | 'Régions'
  | 'Activités'
  | 'Catégories'
  | 'Sports'
  | 'Arts & Culture'
  | 'Événements'

export const Meshing: { [key in MeshTypes]: SeoLinkInterface[] } = {
  Régions: [
    {
      href: '/e/reg/auvergne-rhone-alpes',
      text: 'Auvergne-Rhône-Alpes',
      dataUx: ''
    },
    {
      href: '/e/reg/bourgogne-franche-comte',
      text: 'Bourgogne-Franche-Comté',
      dataUx: ''
    },
    {
      href: '/e/reg/bretagne',
      text: 'Bretagne',
      dataUx: ''
    },
    {
      href: '/e/reg/centre-val--de--loire',
      text: 'Centre-Val de Loire',
      dataUx: ''
    },
    {
      href: '/e/reg/corse',
      text: 'Corse',
      dataUx: ''
    },
    {
      href: '/e/reg/grand--est',
      text: 'Grand Est',
      dataUx: ''
    },
    {
      href: '/e/reg/guadeloupe',
      text: 'Guadeloupe',
      dataUx: ''
    },
    {
      href: '/e/reg/guyane',
      text: 'Guyane',
      dataUx: ''
    },
    {
      href: '/e/reg/hauts-de-france',
      text: 'Hauts-de-France',
      dataUx: ''
    },
    {
      href: '/e/reg/ile-de-france',
      text: 'Île de France',
      dataUx: ''
    },
    {
      href: '/e/reg/la--reunion',
      text: 'La Réunion',
      dataUx: ''
    },
    {
      href: '/e/reg/martinique',
      text: 'Martinique',
      dataUx: ''
    },
    {
      href: '/e/reg/mayotte',
      text: 'Mayotte',
      dataUx: ''
    },
    {
      href: '/e/reg/normandie',
      text: 'Normandie',
      dataUx: ''
    },
    {
      href: '/e/reg/nouvelle-aquitaine',
      text: 'Nouvelle-Aquitaine',
      dataUx: ''
    },
    {
      href: '/e/reg/nouvelle-caledonie',
      text: 'Nouvelle-Calédonie',
      dataUx: ''
    },
    {
      href: '/e/reg/occitanie',
      text: 'Occitanie',
      dataUx: ''
    },
    {
      href: '/e/reg/pays--de--la--loire',
      text: 'Pays de la Loire',
      dataUx: ''
    },
    {
      href: '/e/reg/polynesie--francaise',
      text: 'Polynésie française',
      dataUx: ''
    },
    {
      href: '/e/reg/provence-alpes-cote--d---azur',
      text: 'Provence-Alpes-Côte d\'Azur',
      dataUx: ''
    },
    {
      href: '/e/reg/saint-pierre-et-miquelon',
      text: 'Saint-Pierre-et-Miquelon',
      dataUx: ''
    },
    {
      href: '/e/reg/wallis-et-futuna',
      text: 'Wallis-et-Futuna',
      dataUx: ''
    }
  ],
  Activités: [
    {
      href: '/e/act/atelier',
      text: 'Ateliers',
      dataUx: ''
    },
    {
      href: '/e/act/benevolat',
      text: 'Bénévolat',
      dataUx: ''
    },
    {
      href: '/e/act/competition',
      text: 'Compétitions',
      dataUx: ''
    },
    {
      href: '/e/act/concert',
      text: 'Concerts',
      dataUx: ''
    },
    {
      href: '/e/act/conference',
      text: 'Conférences',
      dataUx: ''
    },
    {
      href: '/e/act/cours',
      text: 'Cours',
      dataUx: ''
    },
    {
      href: '/e/act/exposition',
      text: 'Expositions',
      dataUx: ''
    },
    {
      href: '/e/act/festival',
      text: 'Festivals',
      dataUx: ''
    },
    {
      href: '/e/act/fete',
      text: 'Fêtes',
      dataUx: ''
    },
    {
      href: '/e/act/sejour',
      text: 'Séjours',
      dataUx: ''
    },
    {
      href: '/e/act/sortie',
      text: 'Sorties',
      dataUx: ''
    },
    {
      href: '/e/act/spectacle',
      text: 'Spectacles',
      dataUx: ''
    }
  ],
  Catégories: [
    {
      href: '/e/cat/animaux',
      text: 'Animaux',
      dataUx: ''
    },
    {
      href: '/e/cat/artisanat--et--cuisine',
      text: 'Artisanat et cuisine',
      dataUx: ''
    },
    {
      href: '/e/cat/arts--et--culture',
      text: 'Arts et culture',
      dataUx: ''
    },
    {
      href: '/e/cat/bien-etre',
      text: 'Bien-être',
      dataUx: ''
    },
    {
      href: '/e/cat/ecologie--et--environnement',
      text: 'Ecologie et environnement',
      dataUx: ''
    },
    {
      href: '/e/cat/education',
      text: 'Education',
      dataUx: ''
    },
    {
      href: '/e/cat/humanitaire',
      text: 'Humanitaire',
      dataUx: ''
    },
    {
      href: '/e/cat/loisirs',
      text: 'Loisirs',
      dataUx: ''
    },
    {
      href: '/e/cat/musique',
      text: 'Musique',
      dataUx: ''
    },
    {
      href: '/e/cat/patrimoine',
      text: 'Patrimoine',
      dataUx: ''
    },
    {
      href: '/e/cat/reseaux--pros--et--etudiants',
      text: 'Réseaux pros et étudiants',
      dataUx: ''
    },
    {
      href: '/e/cat/sante',
      text: 'Santé',
      dataUx: ''
    },
    {
      href: '/e/cat/sciences--et--technologie',
      text: 'Sciences et technologie',
      dataUx: ''
    },
    {
      href: '/e/cat/solidarite--et--action--sociale',
      text: 'Solidarite et action sociale',
      dataUx: ''
    },
    {
      href: '/e/cat/spiritualite',
      text: 'Spiritualité',
      dataUx: ''
    },
    {
      href: '/e/cat/sport',
      text: 'Sport',
      dataUx: ''
    }
  ],
  Sports: [
    {
      href: '/e/cat/arts--martiaux',
      text: 'Arts Martiaux',
      dataUx: ''
    },
    {
      href: '/e/cat/athletisme',
      text: 'Athlétisme',
      dataUx: ''
    },
    {
      href: '/e/cat/badminton',
      text: 'Badminton',
      dataUx: ''
    },
    {
      href: '/e/cat/basketball',
      text: 'Basketball',
      dataUx: ''
    },
    {
      href: '/e/cat/bmx',
      text: 'Bmx',
      dataUx: ''
    },
    {
      href: '/e/cat/boxe',
      text: 'Boxe',
      dataUx: ''
    },
    {
      href: '/e/cat/escalade',
      text: 'Escalade',
      dataUx: ''
    },
    {
      href: '/e/cat/escrime',
      text: 'Escrime',
      dataUx: ''
    },
    {
      href: '/e/cat/football',
      text: 'Football',
      dataUx: ''
    },
    {
      href: '/e/cat/gymnastique',
      text: 'Gymnastique',
      dataUx: ''
    },
    {
      href: '/e/cat/handball',
      text: 'Handball',
      dataUx: ''
    },
    {
      href: '/e/cat/hip-hop',
      text: 'Hip-hop',
      dataUx: ''
    },
    {
      href: '/e/cat/judo',
      text: 'Judo',
      dataUx: ''
    },
    {
      href: '/e/cat/krav-maga',
      text: 'Krav-maga',
      dataUx: ''
    },
    {
      href: '/e/cat/kung-fu',
      text: 'Kung-fu',
      dataUx: ''
    },
    {
      href: '/e/cat/marche--nordique',
      text: 'Marche nordique',
      dataUx: ''
    },
    {
      href: '/e/cat/mma',
      text: 'MMA',
      dataUx: ''
    },
    {
      href: '/e/cat/moto',
      text: 'Moto',
      dataUx: ''
    },
    {
      href: '/e/cat/natation',
      text: 'Natation',
      dataUx: ''
    },
    {
      href: '/e/cat/petanque',
      text: 'Pétanque',
      dataUx: ''
    },
    {
      href: '/e/cat/pilates',
      text: 'Pilates',
      dataUx: ''
    },
    {
      href: '/e/cat/plongee',
      text: 'Plongée',
      dataUx: ''
    },
    {
      href: '/e/cat/randonnee',
      text: 'Randonnée',
      dataUx: ''
    },
    {
      href: '/e/cat/roller',
      text: 'Roller',
      dataUx: ''
    },
    {
      href: '/e/cat/rugby',
      text: 'Rugby',
      dataUx: ''
    },
    {
      href: '/e/cat/salsa',
      text: 'Salsa',
      dataUx: ''
    },
    {
      href: '/e/cat/self--defense',
      text: 'Self defense',
      dataUx: ''
    },
    {
      href: '/e/cat/sport',
      text: 'Sport',
      dataUx: ''
    },
    {
      href: '/e/cat/tennis',
      text: 'Tennis',
      dataUx: ''
    },
    {
      href: '/e/cat/tennis--de--table',
      text: 'Tennis de table',
      dataUx: ''
    },
    {
      href: '/e/cat/velo',
      text: 'Vélo',
      dataUx: ''
    },
    {
      href: '/e/cat/volley-ball',
      text: 'Volley-ball',
      dataUx: ''
    },
    {
      href: '/e/cat/vtt',
      text: 'VTT',
      dataUx: ''
    },
    {
      href: '/e/cat/yoga',
      text: 'Yoga',
      dataUx: ''
    },
    {
      href: '/e/cat/zumba',
      text: 'Zumba',
      dataUx: ''
    }
  ],
  'Arts & Culture': [
    {
      href: '/e/cat/arts--et--culture',
      text: 'Arts et Culture',
      dataUx: ''
    },
    {
      href: '/e/cat/chant',
      text: 'Chant',
      dataUx: ''
    },
    {
      href: '/e/cat/chorale',
      text: 'Chorale',
      dataUx: ''
    },
    {
      href: '/e/cat/cirque',
      text: 'Cirque',
      dataUx: ''
    },
    {
      href: '/e/cat/couture',
      text: 'Couture',
      dataUx: ''
    },
    {
      href: '/e/cat/danse',
      text: 'Danse',
      dataUx: ''
    },
    {
      href: '/e/cat/improvisation',
      text: 'Impro',
      dataUx: ''
    },
    {
      href: '/e/cat/loisirs',
      text: 'Loisirs',
      dataUx: ''
    },
    {
      href: '/e/cat/musique',
      text: 'Musique',
      dataUx: ''
    },
    {
      href: '/e/cat/theatre',
      text: 'Théâtre',
      dataUx: ''
    }
  ],
  Événements: [
    {
      href: '/e/evt/clean-up',
      text: 'Clean-up day',
      dataUx: ''
    },
    {
      href: '/e/evt/fete-musique',
      text: 'Fête de la Musique',
      dataUx: ''
    },
    {
      href: '/e/evt/forum-des-associations',
      text: 'Forum des associations',
      dataUx: ''
    },
    {
      href: '/e/evt/giving-tuesday',
      text: 'Giving Tuesday',
      dataUx: ''
    },
    {
      href: '/e/evt/halloween',
      text: 'Halloween',
      dataUx: ''
    },
    {
      href: '/e/evt/journee-patrimoine',
      text: 'Journées du patrimoine',
      dataUx: ''
    },
    {
      href: '/e/evt/movember',
      text: 'Movember',
      dataUx: ''
    },
    {
      href: '/e/evt/octobre-rose',
      text: 'Octobre Rose',
      dataUx: ''
    },
    {
      href: '/e/evt/telethon',
      text: 'Téléthon',
      dataUx: ''
    },
    {
      href: '/e/evt/vacances-ete',
      text: 'Vacances d\'été',
      dataUx: ''
    },
    {
      href: '/e/evt/vacances-toussaint',
      text: 'Vacances Toussaint',
      dataUx: ''
    }
  ]
}
